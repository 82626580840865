import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { all, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { CartContext } from "../App";
import { CartContextType, CartItem, CartItemAdd } from "../models/Cart";
import { Ticket, TicketType } from "../models/Tickets";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { getPlanInCart, planExistsInCart } from "../utils/cart";
import { Button } from "./ui/button";
import { useDarkMode } from "usehooks-ts";

interface Props {
  ticket: Ticket;
}

export const TicketQuantity = ({ ticket }: Props) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode();
  const cart = useSelector((state: State) => state.cart.cart);
  const [quantitySelected, setQuantitySelected] = useState(0);

  useEffect(() => {
    if (cart) {
      const cartItem = cart.filter(
        (cartItem: CartItem) => ticket.uuid === cartItem.ticketUuid
      );
      if (cartItem.length > 0) {
        setQuantitySelected(cartItem[0].quantity);
      } else {
        setQuantitySelected(0);
      }
    }
  }, [cart]);

  const cartContainsNormalTickets = (): boolean => {
    const normalItems = cart.filter(
      (cartItem: CartItem) => cartItem.ticketType == TicketType.ONCE_OFF
    );
    return normalItems.length > 0;
  };

  const getLineTotal = (quantity: number) => {
    // if (ticket.ticketType === TicketType.PAYMENT_PLAN && ticket.perMonthPrice) {
    //   return parseFloat(ticket.perMonthPrice) * quantity;
    // }
    return parseFloat(ticket.price) * quantity;
  };

  const getPlusButtonVariant = () => {
    if (quantitySelected < ticket.ticketOptionsRange) {
      return darkMode.isDarkMode ? "outline" : "default";
    } else {
      return "outline";
    }
  };

  const getMinusButtonVariant = () => {
    if (quantitySelected > 0) {
      return darkMode.isDarkMode ? "outline" : "default";
    } else {
      return "outline";
    }
  };

  const addToCart = (quantity: number) => {
    dispatch(cartActions.ClearCartError());
    const newCartItem: CartItem = {
      ticketUuid: ticket.uuid,
      ticketFee: ticket.fee,
      quantity: quantity,
      name: ticket.name,
      price: ticket.price,
      lineTotal: getLineTotal(1),
      ticketType: ticket.ticketType,
      numberOfMonths: ticket.numberOfMonths,
      perMonthPrice: ticket.perMonthPrice,
      addons: ticket.addons,
      questions: ticket.questions,
    };
    // If we have no cart, just set it
    if (cart.length === 0 && quantity > 0) {
      dispatch(
        cartActions.SetCart({
          cart: [newCartItem],
        })
      );
    } else {
      // If a plan exists in the cart and we are trying to add a non-plan ticket, raise an error
      if (planExistsInCart(cart)) {
        // Get the plan that is in the cart
        const planInCart = getPlanInCart(cart);

        // Display an error message if the plan in the cart is differen to what we are adding
        // if it's the same, we can add it as it's just a quantity change
        if (planInCart.ticketUuid !== newCartItem.ticketUuid) {
          dispatch(
            cartActions.SetCartError({
              cartError:
                "You cannot add a normal ticket when you already have a payment plan in your cart. Remove the payment plan to continue.",
            })
          );
          return;
        }
      }

      // If there are other things in the cart that aren't plans and we try to add a plan
      if (
        cartContainsNormalTickets() &&
        ticket.ticketType == TicketType.PAYMENT_PLAN
      ) {
        dispatch(
          cartActions.SetCartError({
            cartError:
              "You cannot add a payment plan ticket when you already have a normal ticket in your cart. Remove the normal tickets to continue.",
          })
        );
        return;
      }

      // Loop through the cart looking for an entry for that ticket
      const matchingItemIndex = cart.findIndex(
        (cartItem: CartItem) => cartItem.ticketUuid == ticket.uuid
      );

      // If it's already in the cart
      if (matchingItemIndex > -1) {
        const allOtherItems = cart.filter(
          (cartItem: CartItem) => cartItem.ticketUuid != ticket.uuid
        );
        // Check if the current quantity minus the desired will result in negative, if remove it
        if (cart[matchingItemIndex].quantity + quantity < 1) {
          dispatch(
            cartActions.SetCart({
              cart: allOtherItems,
            })
          );
        } else {
          // If the result isn't negative, then add the quantity and calculate a new lineTotal
          const newQuantity = cart[matchingItemIndex].quantity + quantity;
          dispatch(
            cartActions.SetCart({
              cart: [
                ...allOtherItems,
                {
                  ticketUuid: ticket.uuid,
                  ticketFee: ticket.fee,
                  name: ticket.name,
                  price: ticket.price,
                  quantity: cart[matchingItemIndex].quantity + quantity,
                  lineTotal: getLineTotal(newQuantity),
                  ticketType: ticket.ticketType,
                  numberOfMonths: ticket.numberOfMonths,
                  perMonthPrice: ticket.perMonthPrice,
                  addons: ticket.addons,
                },
              ],
            })
          );
        }
      } else {
        // Not in the cart, add it
        if (quantity > 0) {
          dispatch(
            cartActions.SetCart({
              cart: [...cart, newCartItem],
            })
          );
        }
      }
    }
  };

  return (
    <div className="flex xs:ml-2">
      <div>
        <Button
          onClick={() => {
            addToCart(-1);
          }}
          disabled={quantitySelected == 0}
          variant={getMinusButtonVariant()}
          size="icon"
        >
          <MinusIcon className="h-4 w-4" />
        </Button>
      </div>
      <div className="mx-4 flex items-center">
        <h3 className="text-lg dark:text-white">{quantitySelected}</h3>
      </div>
      <div>
        <Button
          onClick={() => {
            if (quantitySelected < ticket.ticketOptionsRange) {
              addToCart(1);
            }
          }}
          disabled={quantitySelected >= ticket.ticketOptionsRange}
          variant={getPlusButtonVariant()}
          size="icon"
          className={quantitySelected < ticket.ticketOptionsRange ? "" : ""}
        >
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};
