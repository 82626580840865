import { useEffect } from "react";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cartActions } from "../store/cart";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export const Completionist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.location.reload();
    dispatch(
      cartActions.SetShowCheckout({
        showCheckout: false,
      })
    );
    dispatch(cartActions.ClearCart());
  }, []);

  return (
    <div className="flex items-center justify-center h-full mt-[0.45rem] mr-1">
      <ExclamationTriangleIcon className="h-5 w-5 text-white mr-1 mt-[0.1rem]" />
      <div className="text-white text-sm">Cart Expired</div>
    </div>
  );
};

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }: any) => {
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, "0");

  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    const totalSeconds = minutes * 60 + seconds;
    const remainingSeconds = totalSeconds % 600; // Modulo 600 for 10 minutes
    const percentage = (remainingSeconds / 600) * 100; // Percentage of the current 10-minute segment
    const circumference = 2 * Math.PI * 18; // Circumference of the circle

    return (
      <div className="flex items-center justify-center">
        <p className="mr-2 -mt-0.5">Time remaining:</p>
        <div className="relative flex items-center justify-center w-10 h-10 -mt-0.5 -mr-0.5">
          <svg className="w-full h-full" viewBox="0 0 40 40">
            <circle
              className="text-gray-500"
              strokeWidth="4"
              stroke="currentColor"
              fill="transparent"
              r="18"
              cx="20"
              cy="20"
            />
            <circle
              className="text-white"
              strokeWidth="4"
              strokeDasharray={circumference}
              strokeDashoffset={(circumference * (100 - percentage)) / 100}
              strokeLinecap="round"
              stroke="currentColor"
              fill="transparent"
              r="18"
              cx="20"
              cy="20"
              style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
            />
          </svg>
          <span className="absolute text-white text-xs">
            {minutes}:{zeroPad(seconds, 2)}
          </span>
        </div>
      </div>
    );
  }
};

interface Props {
  heldUntil: string;
}

export const HoldCountdown = ({ heldUntil }: Props) => {
  return (
    <div className="flex items-center justify-end">
      <Countdown date={new Date(heldUntil)} renderer={renderer} />
    </div>
  );
};
