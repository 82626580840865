import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardElementOptions,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { Error } from "../components/Error";
import { updatePaymentPlanPaymentMethod } from "../queries";
import { cartActions } from "../store/cart";
import { useDarkMode } from "usehooks-ts";
import { Button } from "@/components/ui/button";

interface Props {
  orderUuid: string;
  clientSecret: string;
}

export const ChangePaymentDetailsForm = ({
  orderUuid,
  clientSecret,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const darkMode = useDarkMode();

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     'payment_intent_client_secret'
  //   );

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent.status) {
  //       case 'succeeded':
  //         setMessage('Payment succeeded!');
  //         break;
  //       case 'processing':
  //         setMessage('Your payment is processing.');
  //         break;
  //       case 'requires_payment_method':
  //         setMessage('Your payment was not successful, please try again.');
  //         break;
  //       default:
  //         setMessage('Something went wrong.');
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const result = await stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: "Jenny Rosen",
            },
          },
        })
        .then(async (result) => {
          const paymentMethod = result.setupIntent?.payment_method;
          if (paymentMethod) {
            await updatePaymentPlanPaymentMethod(
              orderUuid,
              result.setupIntent?.payment_method
            );
            dispatch(
              cartActions.SetPaymentPlanUpdated({ paymentPlanUpdated: true })
            );
          }
        })
        .catch((result) => {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (result.error) {
            if (
              result.error.type === "card_error" ||
              result.error.type === "validation_error"
            ) {
              if (result.error.message) {
                setMessage(result.error.message);
              }
            } else {
              setMessage("An unexpected error occurred.");
            }
          }
        });
    }
    setIsLoading(false);
    // todo: no error, set that the order is done
  };

  const cardElementOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: darkMode.isDarkMode ? "#ffffff" : "",
        fontFamily: "Finlandica, sans-serif",
      },
    },
  };

  const cardElementContainerStyle = {
    backgroundColor: darkMode.isDarkMode ? "#27272a" : "",
    border: `1px solid ${darkMode.isDarkMode ? "#3f3f46" : "#d1d5db"}`, // Change this to your desired border color
    padding: "10px",
    borderRadius: "4px",
  };

  return (
    <div className="mt-6">
      <form id="payment-plan-modify" onSubmit={handleSubmit}>
        <div style={cardElementContainerStyle}>
          <CardElement id="payment-element" options={cardElementOptions} />
        </div>
        <div className="mt-4">
          <Button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="w-full"
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <>Update Payment Plan with Card</>
            )}
          </Button>
        </div>
        {/* Show any error or success messages */}
        {message && <Error error={message} />}
      </form>
    </div>
  );
};
