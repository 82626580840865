import { Helmet } from "react-helmet-async";

interface DynamicMetaTagsProps {
  url: string;
  title: string;
  description: string;
}

export const DynamicMetaTags = ({
  url,
  title,
  description,
}: DynamicMetaTagsProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="twitter:domain" content={url} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
