import React, { useState, useEffect, Fragment } from "react";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useSelector } from "react-redux";
import { State } from "../store/store";
import { PaymentDetailsForm } from "../forms/PaymentDetailsForm";
import { Transition } from "@headlessui/react";
import { PaymentPlanSchedule } from "./PaymentPlanSchedule";
import { Order, OrderResponse } from "../models/Order";
import { Card, CardContent } from "./ui/card";
import { useDarkMode } from "usehooks-ts";

interface Props {
  order: OrderResponse;
  redirectUrl?: string;
}

export const PaymentDetails = ({ order, redirectUrl }: Props) => {
  const darkMode = useDarkMode();
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );
  const event = useSelector((state: State) => state.cart.event);

  const stripePromise = loadStripe(
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: event?.organisation?.accountId,
    }
  );

  const appearance: Appearance = {
    theme: "stripe",
    variables: {
      colorText: darkMode.isDarkMode ? "#ffffff" : "",
      fontFamily: "Finlandica, sans-serif",
    },
    rules: {
      ".Label": {
        padding: "6px 0",
        fontWeight: "500",
        // color: darkMode.isDarkMode ? "#ffffff" : "#000000",
      },
      ".Input": {
        backgroundColor: darkMode.isDarkMode ? "#27272a" : "",
        borderColor: darkMode.isDarkMode ? "#3f3f46" : "",
      },
      ".Tab": {
        backgroundColor: darkMode.isDarkMode ? "#27272a" : "",
        borderColor: darkMode.isDarkMode ? "#3f3f46" : "",
      },
    },
  };

  const options: StripeElementsOptions = {
    clientSecret: order?.clientSecret,
    appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Finlandica:wght@400;500;700&display=swap",
      },
    ],
  };

  return (
    <div className="">
      <Card className="lg:mt-4 dark:bg-dark-secondary dark:border-white/5 lg:mb-0 mb-6">
        <CardContent>
          <Transition
            as={Fragment}
            show={!!order}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 scale-100 "
            leaveTo="opacity-0 scale-95 "
          >
            <div className="lg:pl-0.5">
              <div className="mb-3 mt-5">
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium text-gray-900 dark:text-white"
                >
                  Payment Details
                </h2>
              </div>
              <div className=""></div>
              {order?.monthlyTotal && (
                <PaymentPlanSchedule order={order} showPaymentTerms={true} />
              )}
              {order?.clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <PaymentDetailsForm order={order} redirectUrl={redirectUrl} />
                </Elements>
              )}
            </div>
          </Transition>
          {!order && billingDetailsSubmitting && (
            <div className="flex items-center justify-center w-full h-64">
              <svg
                className="animate-spin h-12 w-12 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
        </CardContent>
      </Card>
      {/* <div className="mt-4 flex items-center justify-center">
        <p className="dark:text-white text-sm">
          By purchasing a ticket you agree to our{" "}
          <span className="underline">Terms of Use</span> and{" "}
          <span className="underline">Privacy Policy</span>.
        </p>
      </div> */}
    </div>
  );
};
