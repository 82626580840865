import { useDispatch, useSelector } from "react-redux";
import { State } from "../../store/store";
import { Addon } from "../../models/Tickets";
import { useEffect, useState } from "react";
import { AddonItem } from "./AddonItem";
import { cartActions } from "../../store/cart";
import { CartStep } from "../../constants";
import { CartNav } from "../portal/CartNav";
import { Button } from "../ui/button";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export const Addons = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: State) => state.cart.cart);
  const [addons, setAddons] = useState<Array<Addon>>([]);

  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const containsAddons = useSelector(
    (state: State) => state.cart.containsAddons
  );

  // everytime the cart changes, calculate addons
  useEffect(() => {
    setAddons(
      cart
        .reduce((acc, obj) => acc.concat(obj.addons || []), [] as Addon[])
        .filter(
          (addon, index, self) =>
            self.findIndex((a) => a.uuid === addon.uuid) === index
        )
    );
  }, [cart]);

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-xl">
      <CartNav
        cart={cart}
        addonCart={addonCart}
        leftContent={
          <Button
            size="sm"
            variant="outline"
            className="text-primary"
            onClick={() =>
              dispatch(cartActions.SetPromoCodeOpen({ promoCodeOpen: true }))
            }
          >
            Enter code
          </Button>
        }
        rightContent={
          cart.length > 0 ? (
            <Button
              size="sm"
              variant="outline"
              className="text-primary"
              onClick={() =>
                dispatch(
                  cartActions.SetStep({
                    step: CartStep.CHECKOUT,
                  })
                )
              }
            >
              Checkout
              <ChevronRightIcon className="h-5 w-5 -mr-2" aria-hidden="true" />
            </Button>
          ) : null
        }
      />
      <div className="mx-auto max-w-lg lg:max-w-none px-1">
        <div className="block">
          <h2
            id="summary-heading"
            className="text-lg font-medium text-gray-900 dark:text-white"
          >
            Add-ons
          </h2>
          <p className="text-muted-foreground dark:text-gray-400">
            Enhance your event experience
          </p>
        </div>

        <div className="mt-4 border-t border-gray-200 dark:border-white/5 pt-4 lg:pt-0">
          {addons.map((addon: Addon) => (
            <AddonItem key={addon.uuid} addon={addon} />
          ))}
        </div>
      </div>
      {/* <div className="mt-10 border-t border-gray-200 dark:border-white/5 pt-6 sm:flex sm:items-center sm:justify-between">
        <div className="flex justify-between w-full">
          <div className="order-last hidden lg:block">
            <button
              type="submit"
              onClick={() => {
                dispatch(cartActions.SetStep({ step: CartStep.CHECKOUT }));
              }}
              className="w-full rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 order-1 sm:w-auto"
            >
              <>Continue</>
            </button>
          </div>
          <div className="order-first items-center flex">
            <div className="text-center text-sm text-gray-500 sm:order-2">
              <p>
                <a
                  onClick={() => {
                    dispatch(cartActions.SetStep({ step: CartStep.TICKETS }));
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                >
                  <span aria-hidden="true">&larr; </span>
                  Back to Tickets
                </a>
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
