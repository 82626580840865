import {
  CheckIcon,
  CreditCardIcon,
  HandThumbUpIcon,
  PlayIcon,
  PlusIcon,
  TicketIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { add, format } from "date-fns";
import { useSelector } from "react-redux";
import { State } from "../store/store";
import { classNames } from "../utils/styles";
import { OrderBase } from "../models/Order";
import ordinal from "ordinal";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  order: OrderBase;
  showPaymentTerms: boolean;
  isFirsPaymentPaid?: boolean;
}

export const PaymentPlanSchedule = ({
  order,
  showPaymentTerms,
  isFirsPaymentPaid,
}: Props) => {
  const addonCart = useSelector((state: State) => state.cart.addonCart);

  // With this monthly total, figure out how many payments based on overall total
  const numberOfPayments = Math.ceil(order.total / (order.monthlyTotal || 1));

  const timeline = [...Array(Math.floor(numberOfPayments))].map((_, index) => {
    const isFirstPayment = index === 0;
    const isFinalPayment = index + 1 == numberOfPayments;

    // Target
    let target = `€${order.monthlyTotal?.toFixed(2)}`;
    if (isFirsPaymentPaid) {
      target += " paid";
    } else if (isFinalPayment) {
      target = `€${order.monthlyTotal?.toFixed(2)} and receive your ticket`;
    }

    // Icon
    let icon = CreditCardIcon;
    // If it's the first payment
    if (isFirstPayment) {
      icon = isFirsPaymentPaid ? CheckIcon : PlayIcon;
    } else if (isFinalPayment) {
      icon = TicketIcon;
    }

    // Icon Background
    let iconBackground = "bg-indigo-500";
    if (isFirstPayment) {
      iconBackground = isFirsPaymentPaid ? "bg-green-400" : "bg-gray-400";
    }

    return {
      id: index,
      content: `${
        isFinalPayment ? "Final Payment of" : `${ordinal(index + 1)} Payment of`
      }`,
      target: target,
      date: isFirstPayment
        ? "today"
        : format(add(new Date(), { months: index }), "dd LLL yyyy"),
      datetime: "2020-09-20",
      icon: icon,
      iconBackground: iconBackground,
    };
  });

  const myTimeline = [
    {
      id: 1,
      content: "First Payment of",
      target: `€${order.monthlyTotal}${isFirsPaymentPaid ? " paid" : ""}`,
      date: "today",
      datetime: "2020-09-20",
      icon: isFirsPaymentPaid ? CheckIcon : PlayIcon,
      iconBackground: isFirsPaymentPaid ? "bg-green-400" : "bg-gray-400",
    },
    {
      id: 2,
      content: "Second Payment of",
      target: `€${order.monthlyTotal}`,
      date: format(add(new Date(), { months: 1 }), "dd LLL yyyy"),
      datetime: "2020-09-22",
      icon: CreditCardIcon,
      iconBackground: "bg-blue-500",
    },
    {
      id: 2,
      content: "Final Payment of",
      target: `€${order.monthlyTotal} and receive your ticket`,
      date: format(add(new Date(), { months: 2 }), "dd LLL yyyy"),
      datetime: "2020-09-22",
      icon: TicketIcon,
      iconBackground: "bg-blue-500",
    },
  ];

  console.log(order);
  return (
    <>
      <div className="flow-root mb-8">
        {/* <h2
          id="billing-details-heading"
          className="text-lg font-medium text-gray-900 dark:text-white mb-5"
        >
          Payment Plan Schedule
        </h2> */}
        <ul role="list" className="-mb-8 mt-2 px-1">
          {addonCart.length > 0 && (
            <li key="addon">
              <div className="relative pb-8">
                {0 !== timeline.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={
                        "bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      }
                    >
                      <PlusIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        Once off payment for addons of{" "}
                        <span className="font-medium text-gray-900">
                          &euro;
                          {(
                            addonCart.reduce(
                              (sum, current) => sum + current.lineTotal,
                              0
                            ) +
                            addonCart.reduce(
                              (sum, current) =>
                                sum +
                                current.quantity * parseFloat(current.fee),
                              0
                            )
                          ).toFixed(2)}
                        </span>
                      </p>
                      <div className="-mb-5">
                        <div className="rounded-md bg-blue-50 p-4 mt-4 -mr-[3.25rem]">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <InformationCircleIcon
                                className="h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                              <p className="text-sm text-blue-700">
                                Note that you will not have access to your
                                purchased add-ons until the payment plan is paid
                                in full &{" "}
                                <span className="underline">
                                  are non-refundable
                                </span>
                                .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime="some date">today</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )}
          {timeline.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-zinc-800"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-zinc-800"
                      )}
                    >
                      <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 pl-2">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {event.content}{" "}
                        <span className="font-medium text-gray-900 dark:text-white">
                          {event.target}
                        </span>
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500 dark:text-gray-400">
                      <time dateTime={event.datetime}>{event.date}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {showPaymentTerms && (
          <div className="mt-6">
            <p className="flex-shrink-0 text-gray-400 text-sm">
              By choosing the payment plan option you are agreeing to the
              Payment Plan Terms of Service. You will be charged the first
              amount and the remaining instalments will be automatically charged
              to your card in line with the above schedule. You will be notified
              if an automatic payment fails and it will be re-attempted. Please
              note, you will not receive your ticket until the final payment has
              been charged succesfully.
            </p>

            <p className="flex-shrink-0 text-gray-400 text-sm mt-4">
              Please note before purchasing a Payment Plan&nbsp;
              <strong>all instalment payments are non-refundable.</strong>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
