import { ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

interface Props {
  error: string;
}

export const Error = ({ error }: Props) => {
  return (
    <Alert variant="destructive">
      <ExclamationCircleIcon className="h-4 w-4" />
      <AlertDescription className="-mb-1">{error}</AlertDescription>
    </Alert>
  );
};
