import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { State } from "../store/store";
import { Error } from "../components/Error";
import { OrderResponse } from "../models/Order";
import { Button } from "@/components/ui/button";
import { useDarkMode } from "usehooks-ts";

interface Props {
  order: OrderResponse;
  redirectUrl?: string;
}

export const PaymentDetailsForm = ({ order, redirectUrl }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const event = useSelector((state: State) => state.cart.event);
  const addonCart = useSelector((state: State) => state.cart.addonCart);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSetupIntent, setIsSetupIntent] = useState(false);
  const darkMode = useDarkMode();

  // Decide if the payment is a Payment Intent (Once Off) or Setup Intent (Payment Plan)
  useEffect(() => {
    if (order?.monthlyTotal) {
      setIsSetupIntent(true);
    }
  }, [order]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // If it's a Setup Intent, the call confirmSetup, not payment
    if (event) {
      const paymentReturnURL = `https://${event.organisation.domain}/${event.slug}/complete`;
      if (isSetupIntent) {
        const { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: paymentReturnURL,
          },
        });
        if (error.type === "card_error" || error.type === "validation_error") {
          if (error.message) {
            setMessage(error.message);
          }
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: redirectUrl ? redirectUrl : paymentReturnURL,
          },
        });
        if (error.type === "card_error" || error.type === "validation_error") {
          if (error.message) {
            setMessage(error.message);
          }
        } else {
          setMessage("An unexpected error occurred.");
        }
      }
    } else {
      console.error("Cannot setup Payment because there is no Event");
    }
    setIsLoading(false);
    // todo: no error, set that the order is done
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {order && elements && (
          <Button
            id="submit"
            className="w-full mt-7"
            variant={darkMode.isDarkMode ? "outline" : "default"}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `Pay €${
                order.monthlyTotal
                  ? addonCart
                    ? (
                        order.monthlyTotal +
                        (addonCart.reduce(
                          (sum, current) => sum + current.lineTotal,
                          0
                        ) +
                          addonCart.reduce(
                            (sum, current) =>
                              sum + current.quantity * parseFloat(current.fee),
                            0
                          ))
                      ).toFixed(2)
                    : order.monthlyTotal.toFixed(2)
                  : order.total.toFixed(2)
              }`
            )}
          </Button>
        )}
        {/* Show any error or success messages */}
        {message && (
          <div className="mt-4">
            <Error error={message} />
          </div>
        )}
      </form>
    </div>
  );
};
