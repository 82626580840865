import { Addon, Ticket, TicketQuestion, TicketType } from "./Tickets";

export interface CartItemAdd {
  ticketUuid: string;
  quantity: number;
}

export interface CartItem {
  ticketUuid: string;
  ticketFee: string;
  name: string;
  price: string;
  quantity: number;
  lineTotal: number;
  ticketType: TicketType;
  numberOfMonths?: number;
  perMonthPrice?: string;
  addons?: Array<Addon>;
  questions?: Array<TicketQuestion>;
}

export interface AddonCartItem extends Addon {
  quantity: number;
  lineTotal: number;
}

export type CartContextType = {
  cart: Array<CartItem>;
  setCart: (cart: Array<CartItem>) => void;
};

export enum PromoCodeType {
  REVEAL = "REVEAL",
  DISCOUNT = "DISCOUNT",
}

export interface PromoCode {
  codeType: PromoCodeType;
  ticket: Ticket;
  name: string;
  amount?: string;
  percentage?: string;
  minimumCartValue: string;
  numberOfUses: number;
}

export interface SimpleOrganisation {
  uuid: string;
  name: string;
  domain: string;
  accountId: string;
}

export interface Event {
  uuid: string;
  name: string;
  startDate: Date;
  endDate: Date;
  location: string;
  slug: string;
  logo: string;
  showEventDetails: boolean;
  organisation?: SimpleOrganisation;
  carbonFootprintEnabled: boolean;
}
