import {
  ExclamationCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";
import { Field, Form, FormikProps, withFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { HoldCountdown } from "../components/HoldCountdown";
import { CartStep, NAME_REGEX } from "../constants";
import { AddonCartItem, CartItem, PromoCode } from "../models/Cart";
import { cartActions } from "../store/cart";
import { State } from "../store/store";
import { planExistsInCart } from "../utils/cart";
import toast from "react-hot-toast";
import { store } from "..";
import { TicketQuestions } from "../components/TicketQuestions";
import { TicketAnswer, TicketQuestion } from "../models/Tickets";
import { Button } from "@/components/ui/button";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Card, CardContent } from "@/components/ui/card";
import { useDarkMode } from "usehooks-ts";

export interface BillingFormValues {
  emailAddress: string;
  confirmEmailAddress: string;
  firstName: string;
  lastName: string;
  cart: Array<CartItem>;
  promoCode?: PromoCode;
  addonCart: Array<AddonCartItem>;
  carbonFootprintContribution: number;
  questions: Array<TicketAnswer>;
}

interface OtherProps {
  cart: Array<CartItem>;
  promoCode?: PromoCode;
  carbonFootprintContribution?: number | undefined;
}

const BillingDetailsInnerForm = (
  props: OtherProps & FormikProps<BillingFormValues>
) => {
  const dispatch = useDispatch();
  const { touched, errors, isSubmitting, handleSubmit, cart } = props;
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );
  const order = useSelector((state: State) => state.cart.order);
  const containsAddons = useSelector(
    (state: State) => state.cart.containsAddons
  );
  const [questions, setQuestions] = useState<Array<TicketQuestion> | undefined>(
    undefined
  );

  // Everytime the cart changes, try extract the questions
  useEffect(() => {
    setQuestions(
      cart
        .map((cartItem) => cartItem.questions)
        .filter(
          (questions): questions is TicketQuestion[] => questions !== undefined
        ) // Type guard to ensure questions are not undefined
        .flat()
    );
  }, [cart]);

  // If the user sets the Carbon Footprint at the Billing Details Form, update the Carbon Footprint Contribution
  useEffect(() => {
    props.setFieldValue(
      "carbonFootprintContribution",
      props.carbonFootprintContribution
    );
  }, [props.carbonFootprintContribution]);

  const darkMode = useDarkMode();

  return (
    <Form>
      <Card className="dark:bg-dark-secondary dark:border-white/5 lg:mb-0 mb-6">
        <CardContent>
          <section
            aria-labelledby="billing-details-heading"
            className={`lg:-mt-4 ${
              billingDetailsSubmitting || order ? "pb-4" : ""
            }`}
          >
            <div className="flex justify-between">
              <div className="mt-5 lg:mt-9">
                <h2
                  id="billing-details-heading"
                  className="text-lg font-medium text-gray-900 dark:text-white"
                >
                  Billing Details
                </h2>
              </div>
            </div>
            {/* <Field type="hidden" name="cart" /> */}
            <Field type="hidden" name="promoCode" />
            <Field type="hidden" name="carbonFootprintContribution" />{" "}
            {/* First & Last Name */}
            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  First name
                </label>
                <div className="relative mt-2 rounded-md shadow-sm relative">
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    autoComplete="given-name"
                    disabled={order}
                    className={`block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset dark:focus:ring-indigo-600 focus:ring-primary sm:text-sm sm:leading-6 ${
                      darkMode.isDarkMode
                        ? "bg-zinc-800 border-zinc-700 shadow-md text-white"
                        : "bg-white border-gray-300 shadow-sm text-black"
                    } ${
                      errors.lastName
                        ? "text-red-900 ring-1 ring-inset ring-red-300 dark:ring-red-500"
                        : ""
                    }`}
                  />
                  {order && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center mr-2 text-gray-400">
                      <LockClosedIcon className="h-4 w-4" />
                    </div>
                  )}
                  {errors.firstName && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.firstName && (
                  <p className="mt-2 text-sm text-red-600" id="firstNameError">
                    {errors.firstName}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Last name
                </label>
                <div className="relative mt-2 rounded-md shadow-sm relative">
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    autoComplete="family-name"
                    disabled={order}
                    className={`block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset dark:focus:ring-indigo-600 focus:ring-primary sm:text-sm sm:leading-6 ${
                      darkMode.isDarkMode
                        ? "bg-zinc-800 border-zinc-700 shadow-md text-white"
                        : "bg-white border-gray-300 shadow-sm text-black"
                    } ${
                      errors.lastName
                        ? "text-red-900 ring-1 ring-inset ring-red-300 dark:ring-red-500"
                        : ""
                    }`}
                  />
                  {order && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center mr-2 text-gray-400">
                      <LockClosedIcon className="h-4 w-4" />
                    </div>
                  )}
                  {errors.lastName && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.lastName && (
                  <p className="mt-2 text-sm text-red-600" id="lastNameError">
                    {errors.lastName}
                  </p>
                )}
              </div>
            </div>
            {/* Email */}
            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Email
                </label>
                <div className="relative mt-2 rounded-md shadow-sm relative">
                  <Field
                    type="text"
                    id="emailAddress"
                    name="emailAddress"
                    autoComplete="email"
                    disabled={order}
                    className={`block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset dark:focus:ring-indigo-600 focus:ring-primary sm:text-sm sm:leading-6 ${
                      darkMode.isDarkMode
                        ? "bg-zinc-800 border-zinc-700 shadow-md text-white"
                        : "bg-white border-gray-300 shadow-sm text-black"
                    } ${
                      errors.lastName
                        ? "text-red-900 ring-1 ring-inset ring-red-300 dark:ring-red-500"
                        : ""
                    }`}
                  />
                  {order && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center mr-2 text-gray-400">
                      <LockClosedIcon className="h-4 w-4" />
                    </div>
                  )}
                  {errors.emailAddress && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.emailAddress && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="emailAddressError"
                  >
                    {errors.emailAddress}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Confirm email
                </label>
                <div className="relative mt-2 rounded-md shadow-sm relative">
                  <Field
                    type="text"
                    id="confirmEmailAddress"
                    name="confirmEmailAddress"
                    disabled={order}
                    className={`block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset dark:focus:ring-indigo-600 focus:ring-primary sm:text-sm sm:leading-6 ${
                      darkMode.isDarkMode
                        ? "bg-zinc-800 border-zinc-700 shadow-md text-white"
                        : "bg-white border-gray-300 shadow-sm text-black"
                    } ${
                      errors.lastName
                        ? "text-red-900 ring-1 ring-inset ring-red-300 dark:ring-red-500"
                        : ""
                    }`}
                    onPaste={(e: any) => {
                      toast.error("Please type in your email address");
                      e.preventDefault();
                    }}
                  />
                  {order && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center mr-2 text-gray-400">
                      <LockClosedIcon className="h-4 w-4" />
                    </div>
                  )}
                  {errors.confirmEmailAddress && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors.confirmEmailAddress && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="emailAddressError"
                  >
                    {errors.confirmEmailAddress}
                  </p>
                )}
              </div>
            </div>
            {questions && questions.length > 0 && (
              <TicketQuestions order={order} questions={questions} />
            )}
            {!billingDetailsSubmitting && !order && (
              <div
                className={`mt-10 pt-6 sm:flex sm:items-center sm:justify-between border-t border-gray-200 dark:border-white/5`}
              >
                <div className="flex justify-between w-full">
                  <div className="order-last">
                    <Button
                      disabled={billingDetailsSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant={darkMode.isDarkMode ? "outline" : "default"}
                    >
                      {billingDetailsSubmitting ? (
                        <>
                          Loading...
                          <svg
                            className="animate-spin h-5 w-5 text-blue-200 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <>Next</>
                      )}
                    </Button>
                  </div>
                  <div className="order-first items-center flex">
                    <div className="text-center text-sm text-gray-500 sm:order-2">
                      <Button
                        variant="link"
                        className="-ml-4 dark:text-white"
                        onClick={() => {
                          dispatch(
                            cartActions.SetStep({
                              step: containsAddons
                                ? CartStep.ADDONS
                                : CartStep.TICKETS,
                            })
                          );
                        }}
                      >
                        <ArrowLeftIcon className="h-4 w-4 stroke-2 mr-2" />
                        Back to tickets
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </CardContent>
      </Card>
    </Form>
  );
};

interface BillingDetailsFormProps {
  cart: Array<CartItem>;
  addonCart: Array<AddonCartItem>;
  promoCode?: PromoCode;
  carbonFootprintContribution?: number | undefined;
}

const mapPropsToValues = (
  props: BillingDetailsFormProps
): BillingFormValues => {
  return {
    emailAddress: "",
    confirmEmailAddress: "",
    firstName: "",
    lastName: "",
    cart: props.cart || [],
    addonCart: [],
    carbonFootprintContribution: props.carbonFootprintContribution || 0,
    questions: props.cart.flatMap(
      (cartItem) =>
        cartItem.questions?.map((question) => ({
          uuid: question.uuid,
          answer: "",
        })) || []
    ),
  };
};
// Wrap our form with the withFormik HoC
export const BillingDetailsForm = withFormik<
  BillingDetailsFormProps,
  BillingFormValues
>({
  mapPropsToValues,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnMount: false,
  validationSchema: Yup.object().shape({
    emailAddress: Yup.string()
      .email("Must be a valid email")
      .required("Required"),
    confirmEmailAddress: Yup.string()
      .email("Must be a valid email")
      .required("Required")
      .oneOf([Yup.ref("emailAddress")], "Email does not match"),
    firstName: Yup.string()
      .matches(NAME_REGEX, "Please enter a valid name")
      .required("Required"),
    lastName: Yup.string()
      .matches(NAME_REGEX, "Please enter a valid name")
      .required("Required"),
    questions: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string().required("UUID is required"),
        answer: Yup.string().required("Answer is required"),
      })
    ),
  }),
  handleSubmit: (values) => {
    store.dispatch(
      cartActions.SetBillingDetailsSubmitting({
        billingDetailsSubmitting: true,
      })
    );
    const promoCode = store.getState().cart.appliedPromoCode;
    if (promoCode) {
      values.promoCode = promoCode;
    }
    const addonCart = store.getState().cart.addonCart;
    if (addonCart) {
      values.addonCart = addonCart;
    }
    if (planExistsInCart(values.cart)) {
      store.dispatch(cartActions.CreatePaymentPlan({ order: values }));
    } else {
      store.dispatch(cartActions.CreateOrder({ order: values }));
    }
  },
})(BillingDetailsInnerForm);

/*
answers: [{"uuid": "", ""}]
*/
