import { useSelector } from "react-redux";
import { BillingDetailsForm } from "../forms/BillingDetailsForm";
import { PaymentDetails } from "./PaymentDetails";
import { State } from "../store/store";
import { useEffect, useState } from "react";
import { MobileCart } from "./portal/MobileCart";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { CartNav } from "./portal/CartNav";
import { HoldCountdown } from "./HoldCountdown";
import { CartItems } from "./CartItems";
import { CartTotals } from "./portal/CartTotals";
import { NewEventDetails } from "./portal/NewEventDetails";
import { Card, CardContent, CardHeader } from "./ui/card";
import { getFeatureFlag } from "@/utils/feature-flags";
import { OffsetCarbonFootprint } from "./OffsetCarbonFootprint";

export const Checkout = () => {
  const cart = useSelector((state: State) => state.cart.cart);
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const promoCode = useSelector((state: State) => state.cart.appliedPromoCode);
  const [mobileCartOpen, setMobileCartOpen] = useState(false);
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const order = useSelector((state: State) => state.cart.order);
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );
  const event = useSelector((state: State) => state.cart.event);

  return (
    <div>
      {/* <div className="lg:hidden">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          {cart.length > 0 && (
            <CartNav
              cart={cart}
              addonCart={addonCart}
              rightContent={
                <div>
                  {order && order.heldUntil && (
                    <HoldCountdown heldUntil={order.heldUntil} />
                  )}
                </div>
              }
            />
          )}

          <div className="mt-4 lg:mt-8">
            <BillingDetailsForm
              cart={cart}
              addonCart={addonCart}
              promoCode={promoCode}
              carbonFootprintContribution={carbonFootprintContribution}
            />
          </div>

          {!order && billingDetailsSubmitting ? (
            <div className="flex items-center justify-center w-full h-64">
              <svg
                className="animate-spin h-12 w-12 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <div className="">{order && <PaymentDetails order={order} />}</div>
          )}
        </div>
      </div> */}

      {/* Desktop Version */}
      <div className="lg:max-w-5xl lg:mx-auto w-full mx-auto max-w-xl">
        <div className="hidden lg:block">
          {event && (
            <div>
              <NewEventDetails event={event} />
            </div>
          )}
        </div>

        <div className="lg:hidden">
          {cart.length > 0 && (
            <CartNav
              cart={cart}
              addonCart={addonCart}
              rightContent={
                <div>
                  {order && order.heldUntil && (
                    <HoldCountdown heldUntil={order.heldUntil} />
                  )}
                </div>
              }
            />
          )}
        </div>

        <div className="lg:flex">
          <div className="w-1/2 pr-4 hidden lg:block">
            {order && (
              <div className="mt-[1.55rem]">
                <CartNav
                  cart={cart}
                  hideCart={true}
                  addonCart={addonCart}
                  rightContent={
                    <div className="flex justify-center">
                      {order && order.heldUntil && (
                        <HoldCountdown heldUntil={order.heldUntil} />
                      )}
                    </div>
                  }
                />
              </div>
            )}
            <div className={!order ? "pt-4" : "px-1"}>
              <CartItems cart={cart} addonCart={addonCart} hideTitle={true} />
              <CartTotals />

              {event?.carbonFootprintEnabled && !order && (
                <div>
                  <OffsetCarbonFootprint />
                </div>
              )}
            </div>
          </div>

          <div className="w-full lg:w-1/2 lg:pl-4 lg:pt-6">
            <div className="lg:hidden">
              {event?.carbonFootprintEnabled && !order && (
                <div className="mb-4">
                  <OffsetCarbonFootprint />
                </div>
              )}
            </div>

            <BillingDetailsForm
              cart={cart}
              addonCart={addonCart}
              promoCode={promoCode}
              carbonFootprintContribution={carbonFootprintContribution}
            />

            <div>
              {!order && billingDetailsSubmitting ? (
                <div className="flex items-center justify-center w-full h-64">
                  <svg
                    className="animate-spin h-12 w-12 text-blue-200"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>
                  {order && (
                    <div className="lg:pt-4">
                      <PaymentDetails order={order} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
