import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../store/store";
import { Discounts } from "../Discounts";
import { CartItem } from "../../models/Cart";
import { TicketType } from "../../models/Tickets";
import { formatNumberToMoney } from "@/utils/cart";

export const CartTotals = () => {
  const cart = useSelector((state: State) => state.cart.cart);
  const addonCart = useSelector((state: State) => state.cart.addonCart);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState(0);
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const [paymentPlanItem, setPaymentPlanItem] = useState<CartItem | undefined>(
    undefined
  );
  const totalDiscount = useSelector((state: State) => state.cart.totalDiscount);

  useEffect(() => {
    const paymentPlanItems = cart.filter(
      (cartItem) => cartItem.ticketType === TicketType.PAYMENT_PLAN
    );
    if (paymentPlanItems && paymentPlanItems.length > 0) {
      setPaymentPlanItem(paymentPlanItems[0]);
    } else {
      setPaymentPlanItem(undefined);
    }
  }, [cart]);

  // Totals
  useEffect(() => {
    // Reduce the line totals of tickets
    const ticketsTotal = cart.reduce(
      (sum, current) => sum + current.lineTotal,
      0
    );
    const addonsTotal = addonCart.reduce(
      (sum, current) => sum + current.lineTotal,
      0
    );
    setSubTotal(ticketsTotal + addonsTotal);
  }, [cart, addonCart]);

  // Fees
  useEffect(() => {
    const ticketsFees = cart.reduce(
      (sum, current) => sum + current.quantity * parseFloat(current.ticketFee),
      0
    );

    let addonFees = 0;

    // only include addon fees if it's not a payment plan
    if (!paymentPlanItem) {
      addonFees = addonCart.reduce(
        (sum, current) => sum + current.quantity * parseFloat(current.fee),
        0
      );
    }

    setFees(ticketsFees + addonFees);
  }, [cart, addonCart]);

  return (
    <dl
      className={`space-y-6 dark:border-white/10 text-sm font-medium text-gray-900 ${
        cart.length > 0 ? "border-t border-gray-200 pt-6" : "pt-2"
      }`}
    >
      <div className="flex items-center justify-between">
        <dt className="text-gray-600 dark:text-gray-400">Subtotal</dt>
        <dd className="dark:text-gray-400">{formatNumberToMoney(subTotal)}</dd>
      </div>

      <Discounts />

      {fees > 0 && (
        <div className="flex items-center justify-between">
          <dt className="text-gray-600 dark:text-gray-400">Service Fee</dt>
          <dd className="dark:text-gray-400">{formatNumberToMoney(fees)}</dd>
        </div>
      )}

      {carbonFootprintContribution > 0 && (
        <div className="flex items-center justify-between text-green-500">
          <dt className="flex">
            <img src="leaf-solid.png" className="w-5" />
            <span className="ml-2.5">Carbon Footprint Contribution</span>
          </dt>
          <dd>{formatNumberToMoney(carbonFootprintContribution)}</dd>
        </div>
      )}

      <div className="flex items-center justify-between border-t border-gray-200 dark:border-white/10 pt-6">
        {paymentPlanItem &&
        paymentPlanItem.perMonthPrice &&
        paymentPlanItem.numberOfMonths ? (
          <>
            <div className="w-full">
              <div className="w-full flex items-center justify-between dark:text-white">
                <dt className="text-base">Total due today</dt>
                <dd className="text-base">
                  &euro;
                  {(
                    parseFloat(paymentPlanItem.perMonthPrice) *
                      paymentPlanItem.quantity +
                    fees / paymentPlanItem.numberOfMonths +
                    carbonFootprintContribution / paymentPlanItem.numberOfMonths
                  ).toFixed(2)}
                </dd>
              </div>

              {addonCart.length > 0 && (
                <>
                  <div className="w-full flex items-center justify-between">
                    <dt className="text-base">Month 1 payment</dt>
                    <dd className="text-base">
                      {formatNumberToMoney(
                        parseFloat(paymentPlanItem.perMonthPrice) *
                          paymentPlanItem.quantity +
                          fees / paymentPlanItem.numberOfMonths +
                          carbonFootprintContribution /
                            paymentPlanItem.numberOfMonths
                      )}
                    </dd>
                  </div>
                  <div className="w-full flex items-center justify-between mt-2">
                    <dt className="text-base">Once off add-on payment</dt>
                    <dd className="text-base">
                      {formatNumberToMoney(
                        addonCart.reduce(
                          (sum, current) => sum + current.lineTotal,
                          0
                        ) +
                          addonCart.reduce(
                            (sum, current) =>
                              sum + current.quantity * parseFloat(current.fee),
                            0
                          )
                      )}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <dt className="text-gray-600 font-normal">
                      (Includes Add-on Service fee of &euro;
                      {formatNumberToMoney(
                        addonCart.reduce(
                          (sum, current) =>
                            sum + current.quantity * parseFloat(current.fee),
                          0
                        )
                      )}
                      )
                    </dt>
                    <dd>
                      {formatNumberToMoney(
                        addonCart.reduce(
                          (sum, current) =>
                            sum + current.quantity * parseFloat(current.fee),
                          0
                        )
                      )}
                    </dd>
                  </div>
                  <div className="w-full flex items-center justify-between mt-8">
                    <dt className="text-base">Grand total due today</dt>
                    <dd className="text-base">
                      {formatNumberToMoney(
                        addonCart.reduce(
                          (sum, current) => sum + current.lineTotal,
                          0
                        ) +
                          addonCart.reduce(
                            (sum, current) =>
                              sum + current.quantity * parseFloat(current.fee),
                            0
                          ) +
                          (parseFloat(paymentPlanItem.perMonthPrice) *
                            paymentPlanItem.quantity +
                            fees / paymentPlanItem.numberOfMonths +
                            carbonFootprintContribution /
                              paymentPlanItem.numberOfMonths)
                      )}
                    </dd>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <dt className="text-base dark:text-white">Total</dt>
            <dd className="text-base dark:text-white">
              {formatNumberToMoney(
                subTotal + carbonFootprintContribution + fees - totalDiscount
              )}
            </dd>
          </>
        )}
      </div>
    </dl>
  );
};
