import axios from "axios";
import { ManageOrderModal } from "../order/ManageOrderModal";
import { useEffect } from "react";
import { getEvent } from "../../../queries";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart";
import { Event } from "../../../models/Cart";
import { PoweredBy } from "../../PoweredBy";
import { useLocation, useParams } from "react-router-dom";
import { State } from "../../../store/store";
import { DynamicMetaTags } from "@/components/DynamicMetaTags";

interface Props {
  children: any;
}

export const PortalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { eventSlug } = useParams();
  const organisation = useSelector((state: State) => state.cart.organisation);
  const event = useSelector((state: State) => state.cart.event);

  // Set the axios request interceptor
  axios.interceptors.request.use((config) => {
    // Add context for what Event it is
    config.headers.OrganisationDomain = window.location.host;
    config.headers.EventSlug = eventSlug;
    return config;
  });

  // Get the Event object
  useEffect(() => {
    if (eventSlug) {
      (async function () {
        try {
          let response = await getEvent(window.location.host, eventSlug);
          dispatch(cartActions.SetEvent({ event: response.data as Event }));
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [window.location.host]);

  return (
    <div className="relative dark:bg-dark-primary font-['Finlandica'] px-4 lg:px-0">
      {event && (
        <DynamicMetaTags
          url={event.organisation.domain}
          title={`Ticketr - ${event.name}`}
          description={`Buy tickets to ${event.name}`}
        />
      )}
      {/* <ManageOrderModal /> */}
      {/* <div className="bg-black w-full z-50 relative flex justify-end p-2 border-t-4 border-indigo-500 shadow-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Livello_2"
          viewBox="0 0 150.55 209.09"
          className="h-6 fill-white"
        >
          <g id="Livello_1-2">
            <path d="m110.26,44.92V0h-49.61v47.45H0l19.53,24.22L0,95.89h60.64v48.56c0,21.42,5.62,37.73,16.7,48.49,11.05,10.72,27.99,16.16,50.35,16.16h22.85v-44.8h-22.85c-12.06,0-17.44-5.38-17.44-17.44v-50.97h40.29v-48.44h-40.29v-2.53Z" />
          </g>
        </svg>
      </div> */}
      {children}
    </div>
  );
};
